const local = {
  apiUrl: "http://localhost:8181/api",
  TimeZone: "Africa/Johannesburg",
  jwtSecret: "6tare8753490hstrPste9765v32Wqsa",
  devServer: {
    proxy: "https://poslite-sandbox.premiercredit.co.za/api"
  }
}

const sandbox = {
  apiUrl: "https://poslite-sandbox.premiercredit.co.za/api",
  TimeZone: "Africa/Johannesburg",
  jwtSecret: "6tare8753490hstrPste9765v32Wqsa",
  devServer: {
    proxy: "https://poslite-sandbox.premiercredit.co.za/api"
  }
}

const prod = {
  apiUrl: "https://poslite.premiercredit.co.za/api",
  TimeZone: "Africa/Johannesburg",
  jwtSecret: "6tare8753490hstrPste9765v32Wqsa",
  devServer: {
    proxy: "https://poslite.premiercredit.co.za/api"
  }
}

const choose = {
  local,
  prod,
  sandbox
}

const config = choose['prod']; // process.env['VUE_APP_STAGE'] ? choose[process.env.VUE_APP_STAGE] : choose['sandbox'];
// const config = choose['local'];

module.exports = config;