import Vue from "vue";
import vuex from "vuex";
/*import { alert } from './alert.module';*/
import { accounts } from "./accounts.module";
import { users } from "./users.module";
import { suppliers } from "./suppliers.module";
import { branches } from "./branches.module";
import { channels } from "./channels.module";
import { clients } from './clients.module';
import { thriveData } from './thrivedata.module';
import { leads } from './leads.module';
import { config } from "../config";
import createPersistedState from "vuex-persistedstate";
//import { clients } from './clients.module';
//import { suppliers } from './suppliers.module';
import { devices } from "./devices.module";
//import { transactions } from './transactions.module';

Vue.use(vuex);

export default new vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    accounts,
    //alert,
    users,
    clients,
    thriveData,
    suppliers,
    branches,
    channels,
    leads,
    devices
  }
});
