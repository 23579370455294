import axios from "axios";
import config, { apiUrl } from "../config";

const state = {
  isLoading: false,
  success: null,
  error: null,
  clients: [],
  cards: []
};

const actions = {
  addClients({ commit }, clients) {
    commit("ADD_CLIENTS", clients);
  },
  async getAllClientCards({ commit, rootState, rootGetters }, id) {
    // Start request
    commit("GET_CLIENT_CARDS_REQUEST");
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };

    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    const result = await http.get(`${apiUrl}/client/cards/${id}`, config);

    if (result && result.statusText == "OK") {
      // Commit mutation
      commit("GET_CLIENT_CARDS", result.data);
      // // End request
      commit("GET_CLIENT_CARDS_SUCCESS");
    } else {
    }

    // Return result
    return result;
  },
  async unlinkClientCard({ commit, rootState, rootGetters }, card) {
    // Get device id to update UI, serialNumber & supplierId
    const { Id, clientId } = card;
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    // Send Request
    const result = await http.post(`${apiUrl}/client/unlink`, card, config);

    if (result && result.statusText == "OK") {
      // Commit mutation
      commit("REMOVE_CARD", Id);
      // End request
      //commit("requestSuccess");
    } else {
      commit("GET_CLIENT_CARDS_FAIL");
    }

    commit("REMOVE_CARD", Id);
    // Send Request
    //const result = await http.post(`${apiUrl}/device/remove`, device, config);
  },
  async syncClientById({ commit, rootState, rootGetters }, clientID) {
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    try {
      // Send Request
      const result = await http.get(`${apiUrl}/client/sync/${clientID}`);
      return result;
    } catch (error) {
      const errors = error.toJSON();
      return errors;
    }
  }
};

const mutations = {
  ADD_CLIENTS(state, clients) {
    state.clients = clients;
  },
  GET_CLIENT_CARDS_REQUEST(state) {
    state.isLoading = true;
  },
  GET_CLIENT_CARDS_SUCCESS(state) {
    state.isLoading = false;
    state.success = true;
    state.error = null;
  },
  GET_CLIENT_CARDS_FAIL(state) {
    state.isLoading = false;
    state.success = null;
    state.error = "There was an error(s) with your request.";
  },
  GET_CLIENT_CARDS(state, data) {
    state.cards = data;
  },
  REMOVE_CARD(state, id) {
    // Get all devices in store
    const cards = JSON.parse(JSON.stringify(state.cards));
    // Filter out unlinked device
    const filteredCards = cards.filter(d => d.Id.toString() != id);
    // update state with left over devices
    state.cards = filteredCards;
  }
};

const getters = {
  clients: state => state.clients,
  cards: state => state.cards,
  loading: state => state.isLoading,
  success: state => state.success
};

export const clients = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
