import { leadService } from "../services";
import axios from "axios";
import config, { apiUrl } from "../config";

const state = {
  authenticated: {},
  roles: [],
  leads: [],
  isLoading: false,
  success: null,
  error: null
};

const actions = {
  getAll({ commit }) {
    commit("getAllRequest");

    leadService.getAll().then(
      leads => commit("getAllSuccess", leads),
      error => commit("getAllFailure", error)
    );
  },
  async deleterLead({ commit, rootState }, id) {
    // Get authenticated lead token
    const { token } = rootState.accounts;
    // Get branches froms
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    const result = await http.delete(`${apiUrl}/lead/${id}`);
  },
  async getAllLeads({ commit, rootState }) {
    // Get authenticated lead token
    const { token } = rootState.accounts;
    // Get branches froms
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    const result = await http.get(`${apiUrl}/lead`);


    if (result && result.statusText == "OK") {
      // Update state with updated lead
      commit("updateLeads", result.data);
      // Return latest leads
      return result.data;
    }

    
  },
  async updateLeadStatus({ commit, rootState }, lead) {
    // Start request
    commit("startRequest");
    // Get authenticated lead token
    const { token } = rootState.accounts;
    // Get branches froms
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    try {
      const result = await http.put(`${apiUrl}/lead`, lead);

      console.log(result)
    if (result && result.data.status == "success") {
      commit("requestSuccess");
    } else {
      
    }
    
    return result;
    } catch (error) {
      console.log(error);
      commit("requestFailed");
    }

    
  },
  updateLeads({ commit }, leads) {
    commit("updateLeads", leads);
  },
  complete({ commit }) {
    commit("complete");
  }
};

const mutations = {
  startRequest(state) {
    state.isLoading = true;
  },
  requestSuccess(state) {
    state.isLoading = false;
    state.success = null;
    state.error = null;
  },
  requestFailed(state, payload) {
    state.isLoading = false;
    state.success = null;
    state.error = "There was an error(s) with your request.";
  },
  complete(state) {
    state.isLoading = false;
    state.success = null;
    state.error = null;
  },
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, leads) {
    state.all = { items: leads, loading: false };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  updateLeads(state, leads) {
    state.leads = leads;
  },
  updateLeadStatus(state, leads) {
    state.lead = leads;
  }
};

const getters = {
  isLoggedIn: state => !!state.authenticated,
  leads: state => state.leads,
  loading: state => state.isLoading,
  success: state => state.success,
  error: state => state.error
};

export const leads = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
