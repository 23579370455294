<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'ni ni-tv-2 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="adminUser"
          :link="{
            name: 'Clients',
            path: '/clients',
            icon: 'ni ni-shop text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Referrals',
            path: '/referrals',
            icon: 'ni ni-shop text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="adminUser"
          :link="{
            name: 'Distributors',
            path: '/suppliers',
            icon: 'ni ni-shop text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Transactions',
            path: '/transactions',
            icon: 'ni ni-money-coins text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Payment Batches',
            path: '/disbursements',
            icon: 'ni ni-money-coins text-primary',
          }"
        >
        </sidebar-item>
        <!-- <sidebar-item :link="{
            name: 'Summary Reports',
            path: '/summary-reports',
            icon: 'ni ni-calendar-grid-58 text-primary',
          }">
          </sidebar-item> -->
        <sidebar-item
          v-if="adminUser"
          :link="{
            name: 'Users',
            path: '/users',
            icon: 'ni ni-circle-08 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Pay@ Reports',
            path: '/payat-reports',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Thrive Data',
            path: '/thrive-data',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Thrive Offers',
            path: '/thrive-offers',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Thrive Payments',
            path: '/thrive-payments',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Thrive Disbursements',
            path: '/thrive-disbursements',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Thrive Traders',
            path: '/thrive-traders',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Thrive Mappings',
            path: '/thrive-mappings',
            icon: 'ni ni-single-copy-04 text-primary',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
  computed: {
    adminUser() {
      return this.$store.getters["accounts/user"].supplierIds == ""
        ? true
        : false;
    },
  },
};
</script>
