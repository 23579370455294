import { userService } from '../services';
import { router } from '../routes/router.js';
import axios from 'axios'
import config from '../config'
import jwt from 'jsonwebtoken'

const state = {
  status: '',
  token: '',
  user: {},
  emailaddress: '',
  username: ''
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      userService.login(user.email, user.password)
        .then(resp => {
          const token = resp.token
          const user = resp.user
          commit('auth_success', { token: token, user: user })
          resolve(resp)
        })
        .catch(err => {

          commit('auth_error')
          reject(err)
        })
    })
  },
  isLoggedIn() {
    return this.getters.isLoggedIn;
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      resolve()
    })
  },
  register({ dispatch, commit }, user) {
    commit('registerRequest', user);

    userService.register(user)
      .then(
        user => {
          commit('registerSuccess', user);
          router.push('/login');
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Registration successful', { root: true });
          })
        },
        error => {
          commit('registerFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
  },
  addUser({ dispatch, commit, getters }, user) {
    commit('add_user_request');

    // Get token from the store
    const token = getters.token;

    // Config for post 
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(config.apiUrl + '/user', user, config).then(user => {
    }).catch(err => console.log(err));
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      userService.resetPassword({ token: payload.token, password: payload.password }).then((resp) => {
        commit('logout');
        resolve(resp);
      }).catch(err => {
        commit('auth_error');
        reject(err)
      })

    })
  },
  forgotPassword({ commit }, email) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      userService.forgotPassword(email).then((resp) => {
        const token = resp.token;
        const user = resp.user;
        commit('auth_success', { token, user })
        resolve(resp)
      }).catch(err => {
        commit('auth_error');
        reject(err)
      })

    })
  }
};

const mutations = {
  auth_request(state) {
    state.status = 'loading'
  },
  auth_success(state, data) {

    state.status = 'success'
    state.token = data.token
    state.user = data.user
    state.emailaddress = data.user.EmailAddress
    state.supplierIds = data.user.supplierIds
    state.username = data.user.Name
    axios.defaults.headers.common['Authorization'] = data.token;
  },
  auth_error(state) {
    state.status = 'error'
    state.token = null
    state.user = null
    state.supplierIds = []
    state.emailaddress = ''
    state.username = ''
  },
  logout(state) {
    state.status = ''
    state.token = null
    state.user = null
    state.supplierIds = []
    state.emailaddress = ''
    state.username = ''
  },
  add_user_request(state) {
    console.log('Add user request');
  }
};

const getters = {
  isLoggedIn: state => {
    return jwt.verify(state.token, config.jwtSecret, function (err, decoded) {
      console.log('token', decoded, err)
      if (err) {
        return false;
      }
      return true;
    });
  },
  authStatus: state => state.status,
  user: state => state.user,
  token: state => state.token,
  supplierIds: state => state.supplierIds,
  username: state => state.username,
  emailaddress: state => state.emailaddress,
};

export const accounts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
